import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { PostCallout } from '../components/PostCallout'
import { TwitterShareButton } from '../components/TwitterShareButton'
import Commento from '../components/Commento'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { title: siteTitle, siteUrl } = data.site.siteMetadata
  const { previous, next } = pageContext
  const { title, featuredImage, date, description, tags } = post.frontmatter
  const ogImage = featuredImage ? `${siteUrl}${featuredImage.childImageSharp.fixed.src}` : null
  const { slug } = post.fields
  const postUrl = `${siteUrl}/${slug}`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={title} description={description} image={ogImage} />
      <div className="container blog-post">
        <PostCallout
          title={title}
          slug={slug}
          date={date}
          featuredImage={featuredImage}
          description={description}
          tags={tags}
          key={slug}
          header
          postUrl={postUrl}
        />

        <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: post.html }} />

        <TwitterShareButton title={title} url={postUrl} />
      </div>

      <div className="blog-post-next-links">
        {previous && (
          <Link className="standard-link" to={previous.fields.slug} rel="prev">
            {' '}
            ← {previous.frontmatter.title}
          </Link>
        )}
        {next && (
          <Link className="standard-link" to={next.fields.slug} rel="next">
            {next.frontmatter.title} →
          </Link>
        )}
      </div>

      <div className="container blog-post">
        <Commento pageId={`/${slug}/`} />
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        featuredImage {
          childImageSharp {
            fixed {
              src
            }
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
