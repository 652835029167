import React, { useEffect } from 'react'

// Helper to add scripts to our page
const insertScript = (src, id, parentElement, pageId) => {
  // For local testing
  // window.parent = {
  //   location: {
  //     host: 'ryanboland.com',
  //   },
  // }
  const script = window.document.createElement('script')
  script.async = true
  script.src = src
  script.id = id
  script.dataset.pageId = pageId
  script.dataset.noFonts = true
  parentElement.appendChild(script)
}

// Helper to remove scripts from our page
const removeScript = (id, parentElement) => {
  const script = window.document.getElementById(id)
  if (script) {
    parentElement.removeChild(script)
  }
}

const Commento = ({ pageId }) => {
  useEffect(() => {
    // If there's no window there's nothing to do for us
    if (!window) {
      return
    }
    const document = window.document
    // In case our #commento container exists we can add our commento script
    if (document.getElementById('commento')) {
      insertScript(
        `https://cdn.commento.io/js/commento.js`,
        `commento-script`,
        document.body,
        pageId,
      )
    }
    // Cleanup; remove the script from the page
    return () => removeScript(`commento-script`, document.body)
  }, [pageId])
  return <div id={`commento`} />
}

export default Commento
